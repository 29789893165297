<template>
    <onboarding-layout
        :error-text="errorText"
        :loading="loading"
        :title="$t('remoteNotarization.return.title')"
        :loading-title="$t('global.loadingMessage.loading')"
    >
        <p
            class="text-center"
            v-html="$t('remoteNotarization.return.subTitle')"
        />
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { FlowStatus, getFlowStatus } from '@/services/remoteNotarizationApi'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { getApplicantReturning, getNextSigningAction, NextSigningAction } from '@/services/api'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { validateSession } from '@/services/schedulingApi'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import assert from 'assert'
    import router from '@/routes/router'
    import { MiscThanksReasons, SchedulingThanksReasons } from '@/utils/thanksPageHelpers'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        data() {
            return {
                errorText: '',
                loading: true,
                returnToken: this.$route.query?.p,
            }
        },
        created: function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
        },
        mounted: async function () {
            try {
                if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                    logger.info(`User hit notarization return page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    appSessionStorage.clear()
                    window.location.reload()
                    return
                }

                this.$logEvent('event_applicant_returned_to_notarization')

                if (!this.returnToken) {
                    logger.log('Missing return token, sending to thanks')
                    return router.push({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.authorization } })
                }

                const response = await getApplicantReturning(this.returnToken)

                if (!response.data.success) {
                    logger.log('Malformed or invalid return token, sending to thanks')
                    return router.push({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.authorization } })
                }

                if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    return (window.location.href = `${process.env.VUE_APP_ORIGINATION_BASE_URL}/shared/redirect?p=${response.data.payload.returnToken2}`)
                }

                const nextSigningActionResponse = await getNextSigningAction()
                const nextSigningAction = nextSigningActionResponse.data.payload.nextSigningAction
                logger.info(`Received next signing action ${nextSigningAction}`)
                if (nextSigningAction === NextSigningAction.allApplicantsAlreadySigned) {
                    logger.log(`All applicants have already signed & notarized! Redirecting to welcome page.`)
                    return await this.$router.replace({ path: originationPagePaths.WELCOME })
                } else if (nextSigningAction === NextSigningAction.nextApplicantShouldSign) {
                    logger.log(`Applicant with returnToken ${this.returnToken} already signed & notarized! Redirecting to next applicant's link.`)
                    return await this.$router.replace(nextSigningActionResponse.data.payload.redirectTo)
                }

                const getFlowStatusResponse = await getFlowStatus()
                logger.info(getFlowStatusResponse.data.payload.flowStatus)

                switch (getFlowStatusResponse.data.payload.flowStatus) {
                    case FlowStatus.sessionComplete:
                    case FlowStatus.failedByNotary:
                        return this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: SchedulingThanksReasons.priorCompletedAppointment } })
                    case FlowStatus.canceled:
                        return this.$router.push({ path: originationPagePaths.NOTARY_SCHEDULING, query: { reason: SchedulingThanksReasons.missedAppointment } })
                    default:
                        break
                }

                const validateSessionResponse = await validateSession()

                if (validateSessionResponse.data.payload.sessionValidityStatus === 'TOO_EARLY') {
                    this.loading = false
                    // Invalidate local storage from last time slot
                    appSessionStorage.removeItem(localStorageKey.confirmedTimeSlot)
                    // re-direct to the scheduling confirm page
                    return this.$router.push({
                        path: originationPagePaths.SCHEDULING_CONFIRMATION,
                    })
                }

                if (validateSessionResponse.data.payload.sessionValidityStatus === 'WAY_TOO_LATE') {
                    this.loading = false
                    // Invalidate local storage from last time slot
                    appSessionStorage.removeItem(localStorageKey.confirmedTimeSlot)
                    return this.$router.push({ path: originationPagePaths.NOTARY_SCHEDULING, query: { reason: SchedulingThanksReasons.ineligibleAppointment } })
                }

                if (validateSessionResponse.data.payload.sessionValidityStatus === 'TOO_LATE') {
                    this.loading = false
                    // Invalidate local storage from last time slot
                    appSessionStorage.removeItem(localStorageKey.confirmedTimeSlot)
                    return this.$router.push({ path: originationPagePaths.NOTARY_SCHEDULING, query: { reason: SchedulingThanksReasons.missedAppointment } })
                }

                // This pushes the user on to the notarization queue flow
                await this.$router.push(getNextRoute(this.$router))
            } catch (e) {
                console.log('error')
                console.log(e)
                this.errorText = ApiErrorHandler(e)
                console.log(this.errorText)
            }
            // Don't put this.loading to false (show page) if backend call fails
        },
    }
</script>
